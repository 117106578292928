.custom-map-modal {
    .modal-dialog {
     width: 100%;
     height: 100%;
     max-width: 100%;
     margin: 0;
     padding: 0;
   }
   .modal-content {
     height: auto;
     min-height: 100%;
     max-width: 100%;
     border-radius: 0;
  }
 }